import React, { useContext, useState } from "react";
import { Nav } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { Context as AuthContext } from "../../context/reducers/AuthReducer";
import fr from "../../assets/img/fr.png";
import en from "../../assets/img/en.png";
import { FaBookOpen, FaHandsHelping } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";

import "./SideBar.scss";
import {
  isAdmin,
  isRecruiter,
  isCosmonaute,
} from "../../services/Controller/CurrentUserServices";
import {
  FaUserTie,
  FaRegUser,
  FaUsers,
  FaGlobe,
  FaAddressBook,
  FaQuestionCircle,
  FaFileAlt,
  FaUniversity,
  FaSignOutAlt,
} from "react-icons/fa";
export default function Sidebar() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { signout } = useContext(AuthContext);
  const [langue, setLangue] = useState("fr");
  const [mobileClass, setMobileClass] = useState(false);
  const [isCatalogueOpen, setIsCatalogueOpen] = useState(false);

  const history = useHistory();
  /* get current location */
  const location = useLocation();
  /* use state of Toggle SideBar */
  const [sideBarToggle, setSideBarToggle] = useState("opened");
  /* Layout By Rôle */
  const layout = isAdmin()
    ? "role.administrator"
    : isRecruiter()
    ? "role.recruiter"
    : isCosmonaute
    ? "role.user"
    : null;
  /* all SideBar items */
  const itemLogOut = {
    name: "Se déconnecter",
    icon: <FaSignOutAlt onClick={() => signout(history)} />,
    layout: layout,
  };
  const itemLangue = {
    icon: (
      <img
        className="langue-icon"
        src={langue === "fr" ? fr : en}
        alt="Français"
        onClick={() => setLangue(langue === "fr" ? "en" : "fr")}
      />
    ),
    layout: layout,
  };
  function toggleCatalogue() {
    setIsCatalogueOpen(!isCatalogueOpen);
  }

  const allRoutes = [
    {
      path: "sidebar.dashboard.path",
      name: "sidebar.dashboard.key",
      icon: <FaGlobe />,
      layout: layout,
    },
    {
      path: "sidebar.profil.path",
      name: "sidebar.profil.key",
      icon: <FaRegUser />,
      layout: layout === "role.administrator" ? "" : layout,
    },
    {
      path: "sidebar.missions.path",
      name: layout === "role.recruiter" ? "Mes demandes" : "Mes formations",
      icon: <i className="icon-missions size-35" />,
      layout: layout === "role.administrator" ? "" : layout,
    },
  
    /***********/
    {
      // path: "sidebar.catalog.path",
      name: "Catalogue des formations",
      icon: <FaBookOpen />,
      layout: layout,
      submenu: [
        {
          path: "/formationsdemandees",
          name: "Formations demandées",
          icon: <FaHandsHelping />,
          layout: layout,
        },
        {
          path: "/formations-demandees-par-entreprise",
          name: "Demandes des entreprises",
          icon: <FaCommentAlt />          ,
          layout: layout,
          display: isAdmin(), // Ajoutez cette condition

          
        },
        {
          path: "/formationsproposes",
          name: "Liste des formations",
          icon: <FaBookOpen />,
          layout: layout,
        },
      ],
    },
    // {
    //   path: "sidebar.interviews.path",
    //   name: "sidebar.interviews.key",
    //   icon: <i className="icon-interviews size-35" />,
    //   layout: layout === "role.administrator" ? "" : layout,
    // },
    {
      path: "sidebar.inspirnautes.path",
      name: "sidebar.inspirnautes.key",
      icon: <FaUsers />,
      layout: "role.administrator",
    },
    {
      path: "sidebar.recruiters.path",
      name: "sidebar.recruiters.key",
      icon: <FaUserTie />,
      layout: "role.administrator",
    },
    {
      path: "sidebar.candidatures.path",
      name: "sidebar.candidatures.key",
      icon: <FaAddressBook />,
      layout: "role.administrator",
    },
    {
      path: "sidebar.annexe.path",
      name: "sidebar.annexe.key",
      icon: <FaUniversity />,
      layout: "role.administrator",
    },
    {
      path: "sidebar.documents.path",
      name: "sidebar.documents.key",
      icon: <FaFileAlt />,
      layout: layout,
    },
    {
      path: "sidebar.follow-candidats.path",
      name: "sidebar.follow-candidats.key",
      icon: <FaQuestionCircle />,
      layout: "role.administrator",
    },
  ];

  /* Hide SideBar */
  function hideSideBar() {
    setSideBarToggle("closed");

    if (document.getElementById("header-Section"))
      document.getElementById("header-Section").style.width = "100%";
    if (document.getElementById("content-Layout"))
      document.getElementById("content-Layout").style.marginLeft = "0";
    if (document.getElementById("nav-Content"))
      document.getElementById("nav-Content").style.padding =
        "0px 120px 0px 120px";
    if (document.getElementById("header-Content"))
      document.getElementById("header-Content").style.padding =
        "100px 120px 50px 120px";
    if (document.getElementById("main"))
      document.getElementById("main").style.padding = "50px 120px 100px 120px";
    if (document.getElementById("main-without-header"))
      document.getElementById("main-without-header").style.padding =
        "120px 120px 100px 120px";

    if (document.getElementById("my-Sidebar"))
      document.getElementById("my-Sidebar").style.height = "55px";
  }

  /* Show SideBar */
  function showSideBar() {
    setSideBarToggle("opened");
    if (document.getElementById("hamb-side"))
      document.getElementById("hamb-side").style.height = "100%";
    if (document.getElementById("header-Section"))
      document.getElementById("header-Section").style.width =
        "calc(100% - 120px)";
    if (document.getElementById("content-Layout"))
      document.getElementById("content-Layout").style.marginLeft = "120px";
    if (document.getElementById("nav-Content"))
      document.getElementById("nav-Content").style.padding =
        "0px 100px 0px 100px";
    if (document.getElementById("header-Content"))
      document.getElementById("header-Content").style.padding =
        "100px 100px 50px 100px";
    if (document.getElementById("main"))
      document.getElementById("main").style.padding = "50px 100px 100px 100px";
    if (document.getElementById("main-without-header"))
      document.getElementById("main-without-header").style.padding =
        "120px 100px 100px 100px";
  }
  if (isTabletOrMobile) {
    allRoutes.push(itemLogOut, itemLangue);
  }
  const mobileResponsive = () => {
    if (window.innerWidth > 1024) {
      return false;
    } else return true;
  };
  const changeBackground = () => {
    if (mobileResponsive()) {
      if (window.scrollY >= 80) {
        setMobileClass(true);
      } else {
        setMobileClass(false);
      }
    }
  };
  window.addEventListener("scroll", changeBackground);
  /* Translation Items */
  const { t } = useTranslation();
  return (
    <>
      {sideBarToggle === "opened" ? (
        <Nav
          id="my-Sidebar"
          defaultActiveKey="/"
          variant="pills"
          className="flex-column sidebar bg-sidebar"
        >
          <NavLink
            id="burger"
            className="nav-link menu-Nav text-underline-none"
            to={location.pathname}
            onClick={hideSideBar}
          >
            <i className="m-auto d-block text-center icon-hamburger float-mobile" />
            <span className="text-center d-block">{""}</span>
          </NavLink>
          {allRoutes
            .filter((el) => el.layout === layout)
            .map((route, key) => {
              return (
                <div className="sidebar-item" key={key}>
                  {route.path ? (
                    <NavLink
                      activeClassName="is-active"
                      exact={true}
                      className="nav-link text-underline-none"
                      to={t(route.layout) + t(route.path)}
                      onClick={
                        route.name === "Catalogue des formations"
                          ? toggleCatalogue
                          : mobileResponsive()
                          ? hideSideBar
                          : null
                      }
                    >
                      <i className="m-auto d-block text-center">{route.icon}</i>
                      <span className="text-center d-block">
                        {t(route.name)}
                      </span>
                    </NavLink>
                  ) : (
                    
                      <span
                        className=" text-underline-none nav-link"
                        onClick={
                          route.name === "Catalogue des formations"
                            ? toggleCatalogue
                            : mobileResponsive()
                            ? hideSideBar
                            : null
                        }
                      >
                        <i className="m-auto d-block text-center">
                          {route.icon}
                        </i>
                        <span className="text-center text-underline-none nav-link"> {route.name}</span>
                      </span>
                    
                  )}
                {route.name === "Catalogue des formations" &&
  isCatalogueOpen &&
  route.submenu.map((subItem, subKey) => (
    subItem.display !== false && ( // Vérifiez si l'élément doit être affiché
      <NavLink
        key={subKey}
        activeClassName="is-active"
        exact={true}
        className="nav-link sub-nav-link text-underline-none"
        to={t(subItem.layout) + t(subItem.path)}
        onClick={mobileResponsive() ? hideSideBar : null}
      >
        <i className="m-auto d-block text-center">
          {subItem.icon}
        </i>
        <span className="text-center d-block">
          {t(subItem.name)}
        </span>
      </NavLink>
    )
  ))}
                </div>
              );
            })}
        </Nav>
      ) : (
        <Nav
          defaultActiveKey="/"
          variant="pills"
          className={
            mobileClass ? "flex-column sidebar" : "flex-column sidebar"
          }
          id="hamb-side"
        >
          <NavLink
            to={location.pathname}
            onClick={showSideBar}
            className="navlink-sidebar"
          >
            <i
              id="ham-icon"
              className="text-underline-none color-white icon-hamburger"
            />
          </NavLink>
        </Nav>
      )}
    </>
  );
}
