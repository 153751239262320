import React, { useEffect, useState, useContext } from "react";
import { Table, Card, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Context as MissionContext } from "../../context/reducers/RecruiterReducer";
import NothingToShow from "../../components/NothingToShow/NothingToShow";
import Paginate from "../../components/Paginate/Paginate";
import ToastService from "../../components/Toast/ToastService";
import "./FormationsEntreprises.scss";

export default function FormationsEntreprises() {
  /* Context Api */
  const {
    state,
    getAllDemandedCompanies,
    hideToast,
  } = useContext(MissionContext);

  /* use Translation */
  const { t } = useTranslation();

  /* States */
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const titre = "Titre de la formation"; 
    const nomEntreprise = "Nom de l'entreprise"; 

    getAllDemandedCompanies(titre, nomEntreprise); // Appelle la nouvelle action
  }, []);

  useEffect(() => {
    const data = state?.allDemandedCompanies?.All || [];
    setDisplayedData(data);
    setCurrentItems(data.slice(0, 10)); // Initialisez currentItems pour la première page
  }, [state]);

  function handlePageChange(newItems) {
    setCurrentItems(newItems);
  }

  function showMissionsContent() {
    return (
      <div className="table-responsive-lg mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              <th>Nom de l'entreprise</th>
              <th>Titre de la formation</th>
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.length ? getListMissions() : null}
          </tbody>
        </Table>
        {!currentItems || !currentItems.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData} // Passez toutes les données affichées
            setCurrentItems={handlePageChange}
            itemsPerPage={10} // Nombre d'éléments par page
          />
        )}
      </div>
    );
  }

  function getListMissions() {
    return currentItems.map((el, index) => {
      const entreprises = el.entreprises.map((entreprise) => (
        <div key={entreprise.id}>
          {entreprise.nom} 
        </div>
      ));

      return (
        <tr className="table-content table-padding" key={"Mission" + index}>
          <td className="w-200">
            <div className="mb-2">{entreprises.length ? entreprises : "Non renseigné"}</div>
          </td>
          <td className="w-200">
            <div className="mb-2">{el.titre || "Non renseigné"}</div>
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      {/* Mission Content */}
      <div id="header-Content" className="header-Content">
        <Row className="mt-4">
          <Col lg={6} md={12} xs={12} className="pr-form mb-sm-3">
            <Card.Header>
              <div className="d-inline-text mr-20 current-mision-header roboto-light fontSize-25">
                Demandes des entreprises
              </div>
            </Card.Header>
          </Col>
        </Row>
      </div>
      <div id="main-without-header" className="main-without-header">
        <Tabs activeKey="All" className="mt-3 mb-3 w-100">
          <Tab eventKey="All">
            {showMissionsContent()}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}