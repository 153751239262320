import axios from "axios";
import {
  API_HOST,
  API_ACCEPTED_MISSSIONS,
  API_PAST_MISSIONS,
  API_DECLINED_MISSIONS,
  API_CURRENT_MISSION,
  API_DECLINED_INTERVIEWS,
  API_INTERVIEW_THREE_FIRST,
  API_DASHBOARD_COSMONAUTE,
  API_DASHBOARD_CALENDER,
  API_MISSIONS_COSMONAUTE,
  API_DECLINE_MISSION_COSMONAUTE,
  API_ONE_MISSION_RECRUITER,
  API_THREE_ASSIGNED_MISSION_COSMONAUTE,
  API_FAVORITE_MISSION,
  API_SHOW_MISSION_FAVORITE,
  API_WANTED_MISSIONS_COSMONAUTE,
} from "../../Api";
import axiosService from "../../services/Axios/axiosServices";
import { getUserId } from "../../services/Controller/CurrentUserServices";
import {
  GET_MISSIONS_ASSIGNEDS,
  GET_THREE_INTERVIEWS,
  GET_MISSIONS_ACCEPTED,
  GET_MISSIONS_PAST,
  GET_MISSIONS_DECLINED,
  GET_CURRENT_MISSIONS,
  GET_DECLINED_INTERVIEWS,
  GET_DASHBOARD_DETAIL,
  GET_DASHBOARD_CALENDER,
  GET_ALL_MISSION,
  DECILNE_MESSION_COSMONAUTE,
  DUMP_MISSION,
  ONE_MISSION_USER,
  POST_MISSION_FAVORITE,
  GET_MISSION_FAVORITE,
  DUMP_FAVORITE,
  GET_ALL_WANTED_MISSION,
} from "../constants/Cosmonaute";
import { SHOW_TOAST } from "../constants/Toast";
import axiosServices from "../../services/Axios/axiosServices";

//fetch data language profile
export const GET_Missions_Accepted = (dispatch) => async () => {
  const urlaccepted = API_HOST + API_ACCEPTED_MISSSIONS + getUserId();
  await axios.get(urlaccepted).then(
    (resp) => {
      dispatch({
        type: GET_MISSIONS_ACCEPTED,
        payload: resp.data,
        toastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération languages",
        },
      });
    }
  );
};
export const AssignedMissions = (dispatch) => async () => {
  const urlCosmonaute =
    API_HOST + API_THREE_ASSIGNED_MISSION_COSMONAUTE + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_MISSIONS_ASSIGNEDS,
        payload: response.data,
        ttoastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération missions",
        },
      });
    }
  );
};
export const PastMissions = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_PAST_MISSIONS + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_MISSIONS_PAST,
        payload: response.data,
        ttoastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération missions",
        },
      });
    }
  );
};
export const DeclinedMissions = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_DECLINED_MISSIONS + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_MISSIONS_DECLINED,
        payload: response.data,
        ttoastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération missions",
        },
      });
    }
  );
};
export const UpcomingInterviews = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_INTERVIEW_THREE_FIRST + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_THREE_INTERVIEWS,
        payload: response.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération interviews",
        },
      });
    }
  );
};

export const DeclinedInterviews = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_DECLINED_INTERVIEWS + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_DECLINED_INTERVIEWS,
        payload: response.data,
        toastContent: {
          status: "success",
          body: "success récupération interviews",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération interviews",
        },
      });
    }
  );
};

export const CurrentMissions = (dispatch) => async () => {
  const urlCosmonaute = API_HOST + API_CURRENT_MISSION + getUserId();
  axiosService.get(urlCosmonaute).then(
    (response) => {
      dispatch({
        type: GET_CURRENT_MISSIONS,
        payload: response.data,
        toastContent: {
          status: "success",
          body: "Récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération interviews",
        },
      });
    }
  );
};

export const getDashboardDetail = (dispatch) => () => {
  const url = API_HOST + API_DASHBOARD_COSMONAUTE + getUserId();
  axiosService.get(url).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_DETAIL,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération details",
        },
      });
    }
  );
};

export const getCalenderDetail = (dispatch) => () => {
  const url = API_HOST + API_DASHBOARD_CALENDER + getUserId();
  axiosService.get(url).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_CALENDER,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération calander detail",
        },
      });
    }
  );
};


export const getAllMissions = (dispatch) => async (filters) => {
  const url = API_HOST + API_MISSIONS_COSMONAUTE + getUserId();
  const data = {
    nom_formation:filters?.nom_formation,
    id_type: filters?.id_type,
    id_expertise: filters?.id_expertise,
    mode: filters?.mode,
    active: filters?.active,
    open: filters?.open
}
  await axios.post(url,data).then(
    (resp) => {
      dispatch({
        type: GET_ALL_MISSION,
        payload: resp.data,
        toastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération des missions",
        },
      });
    }
  );
};

export const getAllWantedMissions = (dispatch) => async () => {
  const url = API_HOST + API_WANTED_MISSIONS_COSMONAUTE;
  await axios.get(url).then(
    (resp) => {
      dispatch({
        type: GET_ALL_WANTED_MISSION,
        payload: resp.data,
        toastContent: {
          status: "success",
          body: "récupération validée",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec de récupération des missions",
        },
      });
    }
  );
};

export const declineMission = (dispatch) => async (data, id) => {
  const url = API_HOST + API_DECLINE_MISSION_COSMONAUTE + id;
  await axios.put(url, data).then(
    (resp) => {
      dispatch({
        type: DECILNE_MESSION_COSMONAUTE,
        payload: resp.data,
        toastContent: {
          status: "success",
          body: "mission refusé",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        payload: null,
        toastContent: {
          status: "error",
          body: "Echec  refus de missions",
        },
      });
    }
  );
};

export const dumpMission = (dispatch) => () => {
  dispatch({
    type: DUMP_MISSION,
    payload: null,
    toastContent: null,
  });
};

/* get One Mission Recruiter */
export const getOneMissionUser = (dispatch) => (id) => {
  const url = API_HOST + API_ONE_MISSION_RECRUITER + id;
  axiosServices.get(url).then(
    (profil) => {
      dispatch({
        type: ONE_MISSION_USER,
        payload: profil.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du détail mission",
        },
      });
    }
  );
};

/* Save mission favorite */
export const postMissionFavorite = (dispatch) => (data,id) => {
  // URL save langues
  const URL = API_HOST + API_FAVORITE_MISSION +'/' + (id ? id : getUserId());
  // Call API save langues
  axiosServices.post(URL, data).then(
    (res) => {
      dispatch({
        type: POST_MISSION_FAVORITE,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "Succès d'enregistrement de mission",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec d'enregistrement de la mission",
        },
      });
    }
  );
};
/* get mission favorite */
export const getMissionFavorite = (dispatch) => () => {
  const url = API_HOST + API_SHOW_MISSION_FAVORITE;
  axiosServices.get(url).then(
    (profil) => {
      dispatch({
        type: GET_MISSION_FAVORITE,
        payload: profil.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération du mission préférées",
        },
      });
    }
  );
};

export const dumpMissionFavorite = (dispatch) => () => {
  dispatch({
    type: DUMP_FAVORITE,
    payload: null,
    toastContent: null,
  });
};