/* Dev endpoint */
// export const API_HOST = "http://api.it.prod-projet.com";
// export const API_HOST_LOGO = "http://api.it.prod-projet.com/logos/";

/* Prod endpoint */
// export const API_HOST = "https://api.italents.io";
// export const API_HOST_LOGO = "https://api.italents.io/logos/";

export const API_HOST = "https://api.atalents.fr";
export const API_HOST_LOGO = "https://api.atalents.fr/logos/";

/* All Apis */
export const API_SKILLS_PATH = "/api/skills";
export const API_AUTH_PATH = "/api/login";
export const API_REFRESH_TOKEN = "/api/token/refresh";
export const API_IS_ACTIVE = "/is_active";
export const API_USERS_PATH = "/api/users";
export const API_TYPE_MISSION_PATH = "/api/type_missions";
export const API_TYPE_SKILLS_PATH = "/api/type_skills";
export const API_PROFILS_PATH = "/api/profils";
export const API_ENTREPRISES_PATH = "/api/entreprises";
export const API_CREATE_ENTREPRISE_PATH = "/create/entreprise";
export const API_ACTIVATE_USER_PATH = "/api/user/activate";
export const API_LIST_PROFILS_PATH = "/api/user/listbyprofil";
export const API_LIST_ENTREPRISES_PATH = "/api/entreprises/details";
export const API_UPLOAD_FORM_FILE = "/file/manager";
export const API_DETAILS_USER = "/api/details/user/";
export const API_UPLOAD_LOGO = "/api/upload/logo";
export const API_UPDATE_PROFIL = "/api/update/profil/";
export const API_UPDATE_PROFILHEADER = "/api/update/profilheader/";
export const API_UPLOAD_FILE = "/api/upload/video";
export const API_LAST_FILE = "/api/get/last/file/";
export const API_delete_VIDEO = "/api/delete/video/";
export const API_LIST_EMAILS = "/list/emails";
export const API_UPDATE_SKILLS = "/api/skills/update/";
export const API_UPDATE_LANGUAGES = "/api/languages/update/";
export const API_PROFILS_BY_SKILLS = "/api/profils/by/skills";
export const API_STATUS_Skills_PATH = "/api/skills/status/profil/";
export const API_PROFIL_DETAILS = "/api/profil/details/";
export const API_LIST_LANGUAGES = "/api/lagauages/status/profil/";
export const API_POST_RECRUITER_PATH = "/api/post_recruiters/";
export const API_MISSIONS_BY_RECRUITER = "/api/missions/details/";
export const API_MISSION_BY_ID = "/api/mission/details/";
export const API_ALL_MISSIONS = "/api/missions/details";
export const AFFECT_PROFIL_MISSION = "/api/affect/profil/";
export const API_HOMEPAGE_CONTENT_PATH = "/api/home/page/details";
export const API_ADD_HOMEPAGE_CONTENT_PATH = "/api/add/home/page/details";
export const API_EDIT_HOMEPAGE_CONTENT_PATH = "/api/edit/home/page/details/";
export const API_SHOW_EN_ATTENTE_PROFILS = "/api/show/en_attente/profils/";
export const API_SHOW_AFFECTED_PROFILS = "/api/show/profils/";
export const API_PRESELECT_CANDIDATE = "/api/preselect/condidate";
export const API_REJECT_CANDIDATE_BEFORE_INTERVIEW = "/api/reject/condidate";
export const API_PRESELECTED_MISSIONS = "/api/profil/details/";
export const API_ACCEPT_MISSION = "/api/accept/interview";
export const API_REFUS_MISSION = "/api/refus/interview";
export const API_DECLINED_INTERVIEWS = "/api/list/declined/interviews/profile/";
export const API_FOLLOW_CANDIDATS = "/api/follow/candidats/admin";
export const API_LIST_INTERVIEWS = "/api/interviews/mission/";
export const API_HIRE_CANDIDATE = "/api/hire/candidate/";
export const API_REJECT_CANDIDATE_AFTER_INTERVIEW = "/api/reject/candidate/after/interview/";
export const API_PAST_MISSIONS = "/api/list/past/missions/";
export const API_ACCEPTED_MISSSIONS = "/api/list/accepted/missions/";
export const API_DECLINED_MISSIONS = "/api/list/declined/missions/";
export const API_ASSIGNED_MISSIONS = "/api/list/assigned/missions/";
export const API_THREE_ASSIGNED_MISSION_COSMONAUTE = "/api/first/three/assigned/mission/cosmonaute/";
// new api
export const API_INTERVIEW_THREE_FIRST = "/api/first/three/upcoming/interview/cosmonaute/";
export const API_THREE_FIRST_INTERVIEWS = "/api/first/three/upcoming/interviews/";
export const API_INTERVIEWS_USER = "/api/upcoming/interviews/";
export const API_CURRENT_MISSION = "/api/current/mission/";
export const API_COMPLETED_MISSIONS_RECRUITER = "/api/completed/missions/recruiter/";
export const API_PROPOSED_MISSIONS_RECRUITER = "/api/proposed/missions/recruiter/";
export const API_COUNT_CANDIDATS_RECRUITER = "/api/preselected/candidats/recruiter/";
export const API_COUNT_INTERVIEWS_RECRUITER = "/api/interviews/missions/recruiter/";
export const API_PROPOSED_MISSIONS_RECRUITER_DETAILS = "/api/proposed/missions/with/profils/recruiter/";
export const API_ONGOING_MISSIONS_RECRUITER_DETAILS = "/api/ongoing/missions/with/profils/recruiter/";
export const API_FINISHED_MISSIONS_RECRUITER_DETAILS = "/api/finished/missions/with/profils/recruiter/";

/* RESUME CANDIDATE */
export const API_UPLOAD_RESUME = "/api/upload/";
export const API_GET_RESUME = "/api/get/last/file/";
export const API_PRINT_CV = "/printCandidateResume/";

/* lOGO COSMONAUTE */
export const API_UPLOAD_LOGO_CANDIDATE = "/api/upload/logo";
export const API_GET_LOGO_CANDIDATE = "/api/get/last/file/logo/";
/* pourcentage condidat*/
export const API_POURCENTAGE_CONDIDAT = "/api/profil/percentage/";
/* INTERVIEW COSMONAUTE */
export const API_ALL_INTERVIEW_COSMONAUTE = "/api/all/interviews/";
export const API_DECLINE_INTERVIEW = "/api/decline/interview/";
export const API_ACCEPT_INTERVIEW_COSMONAUTE = "/api/interview/accept/";
export const API_CHANGE_DATE_COSMONAOTE = "/api/interview/changedate/";

export const API_DECLINE_MISSION_COSMONAUTE = "/api/decline/mission/";

/* PROFILE RECRUITER */
export const API_RECRUITER_DETAILS_PATH = "/api/recruiter/details/";
export const API_EDIT_RECRUITER_PATH = "/api/edit/recruiter/";
export const API_EDIT_PROFIL_RECRUITER = "/api/edit/profil/recruiter/";
export const API_EDIT_DETAIL_PROFIL_RECRUITER = "/api/edit/detail/profil/recruiter/";
export const API_DOMAIN_RECRUITER_PATH = "/api/domain_recruiters";
export const API_UPLOAD_LOGO_RECRUITER = "/api/upload/logo";
export const API_GET_LOGO_RECRUITER = "/api/get/last/file/logo/";

/* DASHBOARD RECRUITER */
export const API_THREE_FIRST_UPCOMMING_INTERVIEWS_RECRUITER = "/api/first/three/upcoming/interviews/recruiter/";
export const API_THREE_FIRST_ONGOING_MISSIONS_RECRUITER = "/api/first/three/ongoing/missions/recruiter/";
export const API_THREE_FIRST_PROPOSED_MISSIONS_RECRUITER = "/api/first/three/proposed/missions/recruiter/";
export const API_DASHBOARD_RECRUITER = "/api/count/recruiter/";
export const API_POURCENTAGE_RECRUITER = "/api/entreprise/percentage/";

/* INTERVIEW RECRUITER */
export const API_ALL_INTERVIEW_RECRUITER = "/api/interviews/rectruiter/";
export const API_INTERVIEW_RECRUITER = "/api/interviews/";
export const API_REJECT_INTERVIEW_RECRUITER = "/api/interview/reject/";
export const API_DASHBOARD_COSMONAUTE = "/api/number/missions/profil/";
export const API_DASHBOARD_CALENDER = "/api/cosmonaute/calander/";
export const API_MISSIONS_COSMONAUTE = "/api/recruiter/all/missions/";
export const API_WANTED_MISSIONS_COSMONAUTE = "/api/admin/missions";
export const API_MISSIONS_COSMONAUTE_UPDATED = "/api/list/missions/cosmonaute/";

/* MISSION RECRUITER */
export const API_ALL_MISSIONS_RECRUITER = "/api/recruiter/all/missions/";
export const API_ALL_PROPOSED_MISSIONS_RECRUITER = "/api/admin/missions/formateur";
export const API_ALL_DEMANDED_MISSIONS_RECRUITER = "/api/admin/missions/entreprise";
export const API_ALL_DEMANDED_COMPANIES = "/api/admin/missions/entrepriseAssocie";
export const API_ONE_MISSION_RECRUITER = "/api/mission/details/";
export const API_PROPOSED_MISSION_RECRUITER = "/api/proposed/profils/";
export const API_REMOVE_PROFILE_MESSION_RECRUITER = "/api/condidate/";
export const API_CONFIRM_MISSION_RECRUITER = "/api/mission/";

export const API_ADD_NEW_MISSION = "/api/add/mission/";
export const API_EDIT_MISSION = "/api/edit/mission/";
export const API_DELETE_MISSION_PATH = "/api/delete/mission/";
export const API_RECRUTE_CANDIDATE_MISSION = "/api/condidate/recruited/";
export const API_REJECT_CANDIDATE_MISSION = "/api/condidate/rejected/";


/* RECRUITERS ADMINISTRATOR */
export const API_ALL_RECRUITERS_ADMINISTRATOR = "/api/admin/entreprises";
export const API_ONE_RECRUITER_ADMINISTRATOR = "/api/admin/entreprises/";

/*Dashboard Admin */
export const API_MISSION_STATS = "/api/admin/dashboard/missionStatistique ";
export const API_CANDIDATE_BY_DAY_STATS = "/api/admin/dashboard/CountCandidatesByDate";
export const API_MISSION_BY_DAY_STATS = "/api/admin/dashboard/CountMissionsByDate";
export const API_USERS_STATS = "/api/admin/dashboard/stats";
export const API_TOP_RECRUITERS = "/api/admin/dashboard/topRecruiters";

/* INSPIRNAUTES ADMINISTRATOR */
export const API_ALL_INSPIRNAUTES_ADMINISTRATOR = "/api/admin/formateurs";
export const API_ONE_INSPIRNAUTE_ADMINISTRATOR = "/api/admin/cosmonautes/";

/* CONDIDATURES ADMINISTRATOR */
export const API_CANDIDATURES_RECRUITER_ADMINISTRATOR = "/api/admin/condidatures/recruiter";
export const API_CANDIDATURES_PROFIL_ADMINISTRATOR = "/api/admin/condidatures/profil";
export const API_VALIDATION_CONDIDATURES_ADMINISTRATOR = "/api/admin/condidatures/";

/* SKILLS ADMINISTRATOR */
export const API_SKILLS_ADMINISTRATOR = "/api/admin/skills";
export const API_SKILLS_ADMINISTRATOR_EDIT = "/api/admin/skills/edit";

/* TYPE SKILLS ADMINISTRATOR */
export const API_TYPE_SKILLS_ADMINISTRATOR = "/api/admin/type/skills";/* GET TYPE SKILLS ADMINISTRATOR */
export const API_MY_TYPE_SKILLS_ADMINISTRATOR = "/api/admin/type/skills/user"; /*Get only my type skills*/
export const API_TYPE_SKILLS_ADMINISTRATOR_MISSION = "/api/admin/type/skills/mission"; /*Get type skills utilises*/

/* DOMAIN ADMINISTRATOR */
export const API_DOMAIN_RECRUITER_ADMINISTRATOR = "/api/admin/expertise/mission"; /***Get utilisés* */
export const API_MY_DOMAIN_RECRUITER_ADMINISTRATOR = "/api/admin/expertise/user"; /******Get only my domains */
export const API_ADD_DOMAIN_RECRUITER_ADMINISTRATOR = "/api/admin/expertise";/****Add domain */                          
/* Modes */
export const API_ALL_MODES = "/api/admin/modes"; /***Get all modes* */
/* my Modes */
export const API_ALL_MY_MODES = "/api/admin/user/modes"; /***Get all my modes* */


/* MISSIONS ADMINISTRATOR */
export const API_ALL_MISSION_ADMINISTRATOR = "/api/admin/missions";
export const API_ONE_MISSION_ADMINISTRATOR = "/api/admin/missions/";
export const API_ADD_MISSION_ADMINISTRATOR = "/api/add/mission/";
export const API_DELETE_MISSION_ADMINISTRATOR = "/api/admin/missions/";
export const API_INTERRUPT_MISSION_ADMINISTRATOR = "/api/admin/interrupt/mission/";

/* MISSIONS PROFILES */
export const API_MATCHING_PROFILES_ADMINISTRATOR = "/api/admin/mission/profils/details/";
export const API_AFFECT_PROFILE_MESSION_ADMINISTRATOR = "/api/admin/affect/condidate";
export const API_REMOVE_PROFILE_MESSION_ADMINISTRATOR = "/api/admin/disaffect/condidate";

/* FOLLOW UP CANDIDATURES */
export const API_FOLLOWUP_MISSIONS_ADMINISTRATOR = "/api/admin/follow/condidat";
export const API_FOLLOWUP_INTERVIEWS_ADMINISTRATOR = "/api/admin/follow/condidat/interviews";

/* INSCRIPTION CANDIDATE */
export const API_ADD_INSPIRNAUTE = "/addCandidat";
export const API_LIST_SKILLS = "/skillsByType";

/* INSCRIPTION RECRUTER */
export const API_ADD_RECRUTER = "/addRecruiter";
export const API_DOMAIN_RECRUTER = "/domainsrecruiter";

/* Contact*/
export const API_CONTACT = "/contact";

/* Forget Password*/
export const API_FORGET_PASSWORD = "/reset/reset-password";
export const API_RESET_PASSWORD = "/reset/edit_password/";

/* SKILLS CANDIDATOR */
export const ALL_LIST_SKILLS = "/skillsByType";
export const API_SAVE_SKILLS = "/api/editCandidateSkills/";

/* TYPE dev SKILLS CANDIDATOR */
export const SAVE_ALL_TYPE_SKILLS = "/api/editCandidateTypeSkills/";


/* EXPERIENCE CANDIDATE */
export const API_WORK_BY_PROFIL_PATH = "/profil/all/work/";
export const API_ADD_WORK_EXP_PATH = "/profil/add/work/";
export const API_UPDATE_WORk_PATH = "/profil/update/work/";
export const API_REMOVE_WORK_EXP_PATH = "/profil/remove/work/";
export const API_SAVE_WORK_EXPERIENCE = "/api/editCandidateExperience/";

/* EDUCATION CANDIDATE */
export const API_ADD_EDUCATION_PATH = "/api/profil/add/education/";
export const API_SAVE_EDUCATION = "/api/editCandidateEducation/";
export const API_REMOVE_EDUCATION_PATH = "/api/profil/remove/education/";
export const API_EDUCATION_BY_PROFIL = "/api/profil/all/education/";
export const API_UPDATE_EDUCATION_PATH = "/api/profil/update/education/";

/* Langues */
export const API_SAVE_LANGUES = "/api/editCandidateLanguage/";
export const API_GET_LANGUES = "/langues";
/*Contact Admin*/
export const API_CONTACT_ADMIN = "/api/contactadmin";

/* check email */
export const API_USER_EMAIL = "/usersemails";

/* document cosmonaute */
export const API_ADD_COSMONAUTE_DOCUMENT = "/api/createDocuments/";
export const API_DELETE_COSMONAUTE_DOCUMENT = "/api/profilDocument/delete";
export const API_GET_COSMONAUTE_DOCUMENT = "/api/profilDocuments/";
export const API_EDIT_COSMONAUTE_DOCUMENT = "/api/profilDocuments/edit/";

/* upload conract and cra */
export const API_ADD_CONTRACT = "/api/admin/contrat/resendContrat";
export const API_GET_CONTRACT = "/api/admin/contrat/show/";

/* update and add cra */
export const API_ADD_CRA = "/api/admin/contrat/edit/";

/* mission recruted */
export const API_MISSION_RECRUTED = "/api/missionsprofil/";

/* GET MISSION with document for recruter */
export const API_DOCUMENT_RECRUTER = "/api/recruiter/documents/";

/* get all CANDIDAT recruted for admin*/
export const API_RECRUTED_CANDIDAT = "/api/admin/allrecruited";

/* import contrat Entreprise */
export const API_RESEND_CONTRAT_RECRUITER =
  "/api/recruiter/documents/resendContrat";

/* get mission Entreprise  detail */
export const API_DETAIL_MISSION_RECRUTER = "/api/missionDetails/";

/* get all recruiter for admin have mission recruited*/
export const API_RECRUITER_ADMIN = "/api/admin/allrecruiters";

/* send mail to contrat and cra candidat */
export const API_SEND_MAIL_CRA_CONTRAT = "/api/admin/contrat/sendContrat";

/* SEND MAIL TO recruiter */
export const API_SEND_MAIL_VOICES = "/api/recruiter/documents/sendContrat";

/* post mission favorite*/
// export const API_FAVORITE_MISSION = "/api/preferred/mission/specificMission";
export const API_FAVORITE_MISSION = "/api/add/mission";


/* get mission favorite*/
export const API_SHOW_MISSION_FAVORITE = "/api/preferred/mission/show";

/* send voices to recruiter */
export const API_SEND_VOICES_ADMIN = "/api/recruiter/documents/addInvoice/";

/* REGIONS */
export const API_REGIONS = "https://geo.api.gouv.fr/regions";


export const API_CONTACT_ICONE = "/api/linkEntrepriseToMission/";