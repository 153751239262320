/* Profile */
export const SHOW_RCRUITER_PROFILE = "SHOW_RCRUITER_PROFILE";
export const DOMAIN_RECRUITER = "DOMAIN_RECRUITER";
export const EDIT_RCRUITER_PROFILE = "EDIT_RCRUITER_PROFILE";
export const DUMP_RECRUITER = "DUMP_RECRUITER";
/* Logo Recruiter */
export const GET_RECRUITER_LOGO = "GET_RECRUITER_LOGO";
export const RECRUITER_LOGO = "RECRUITER_LOGO";
export const DUMP_LOGO_RECRUITER = "DUMP_LOGO_RECRUITER";
/* Interviews */
export const ALL_INTERVIEWS_RECRUITER = "ALL_INTERVIEWS_RECRUITER";
export const PROPOSE_INTERVIEW_RECRUITER = "PROPOSE_INTERVIEW_RECRUITER";
export const CHANGE_INTERVIEW_RECRUITER = "CHANGE_INTERVIEW_RECRUITER";
export const DECLINE_INTERVIEW_RECRUITER = "DECLINE_INTERVIEW_RECRUITER";
export const DUMP_INTERVIEW = "DUMP_INTERVIEW";
/* Dashboard */
export const THREE_FIRST_PROPOSED_MISSIONS = "THREE_FIRST_PROPOSED_MISSIONS";
export const THREE_FIRST_UPCOMMING_INTERVIEWS =
  "THREE_FIRST_UPCOMMING_INTERVIEWS";
export const THREE_FIRST_ONGOING_MISSIONS = "THREE_FIRST_ONGOING_MISSIONS";
export const DASHBOARD_RECRUITER = "DASHBOARD_RECRUITER";
/* Mission */
export const ALL_MISSIONS_RECRUITER = "ALL_MISSIONS_RECRUITER";
export const ALL_PROPOSED_MISSIONS_RECRUITER = "ALL_PROPOSED_MISSIONS_RECRUITER";
export const ALL_DEMANDED_MISSIONS_RECRUITER = "ALL_DEMANDED_MISSIONS_RECRUITER";
export const ONE_MISSION_RECRUITER = "ONE_MISSION_RECRUITER";
export const ALL_DEMANDED_COMPANIES = "ALL_DEMANDED_COMPANIES"; // Nouvelle constante
export const REMOVE_PROFIL_MISSION_RECRUITER =
  "REMOVE_PROFIL_MISSION_RECRUITER";
export const PROPOSED_PROFILES_MISSION_RECRUITER =
  "PROPOSED_PROFILES_MISSION_RECRUITER";
export const ADD_NEW_MISSION = "ADD_NEW_MISSION";
export const EDIT_MISSION = "EDIT_MISSION";
export const DELETE_MISSION = "DELETE_MISSION";
export const CONFIRM_MISSION = "CONFIRM_MISSION";
export const REJECT_CANDIDATE = "REJECT_CANDIDATE";
export const RECRUTE_CANDIDATE = "RECRUTE_CANDIDATE";
export const DUMP_MISSION = "DUMP_MISSION";
export const POURCENTAGE_RECRUITER = "POURCENTAGE_RECRUITER";
/* document */
export const GET_RECRUTER_DOCUMENT = "GET_RECRUTER_DOCUMENT";

// ../constants/Recruiter.js
export const CONTACT_ADMIN_SUCCESS = 'CONTACT_ADMIN_SUCCESS';
export const CONTACT_ADMIN_FAILURE = 'CONTACT_ADMIN_FAILURE';

